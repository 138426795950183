import axios from 'axios';
import _ from 'lodash';

import * as types from 'constants/ActionTypes';
import configuration from 'network/configuration';
import { UrlEnum } from 'util/utils';

export const fetchEnabledService = () => (dispatch) => {
    const service = process.env.REACT_APP_ENABLED_SERVICE;
    dispatch({
        type: types.FETCH_ENABLED_SERVICE,
        payload: service,
    });
    return service;
};

// #region login
export const fetchLoginState =
    (email, password, redirection, termsRequired) => async (dispatch) => {
        let user = null;

        const object = configuration.fetchWebsiteObject();

        const response = await axios.post(
            '/api/login',
            {
                username: email,
                password: password,
            },
            object,
        );

        user = response.data;

        dispatch({
            type: types.FETCH_LOGIN,
            payload: user,
        });

        if (user != null && redirection) {
            // user must explicitly agree to terms & conditions
            if (!termsRequired || user.agree_terms) {
                redirection();
            }
        }
    };

export const logout = (href) => async (dispatch) => {
    const object = configuration.fetchWebsiteObject();
    await axios.get('/api/logout', object);
    dispatch({
        type: types.FETCH_LOGIN,
        payload: false,
    });
    dispatch({
        type: types.FETCH_CART_ITEMS_COUNT,
        payload: null,
    });

    if (href != null) {
        window.location.href = href;
    } else {
        window.location.href = UrlEnum.HOME;
    }
};

export const fetchUser = (firstTimeLoading) => async (dispatch) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    const object = configuration.fetchWebsiteObject();

    const res = await axios.get('/api/current_user', object);
    var user = res.data;

    dispatch({
        type: types.FETCH_LOGIN,
        payload: user,
    });

    // get selected customer information if needed

    try {
        if (firstTimeLoading) {
            // dispatch this first, so the rest of the UI can continue to load while we fetch the customer from the backend
            dispatch({
                type: types.FETCH_SELECTED_CUSTOMER,
                payload: {},
            });
        }
        if (!_.isEmpty(user?.customer_id)) {
            const object = configuration.fetchWebsiteObject();
            const response = await axios.get(`/api/selected_customer`, object);
            const customer = response.data;
            dispatch({
                type: types.FETCH_SELECTED_CUSTOMER,
                payload: customer,
            });
        }
    } catch (error) {
        dispatch({
            type: types.FETCH_SELECTED_CUSTOMER,
            payload: {},
        });
    }
};

// #endregion

// #region website config
export const fetchWebsiteConfiguration = () => async (dispatch) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    const params = configuration.fetchWebsiteObject();
    const res = await axios.get(`/api/website_configuration`, params);

    dispatch({ type: types.FETCH_WEBSITE_CONFIG, payload: res.data });
};
// #endregion

// #region categories
export const fetchCategories = () => async (dispatch) => {
    let categories = {};
    try {
        dispatch({
            type: types.FETCH_CATEGORIES,
            payload: [],
        });
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get('/api/categories', object);
        categories = response.data;
    } catch (error) {
        // if get categories error redirect to products page
        console.error('/api/Categories Get Error: ', error);
    }

    dispatch({
        type: types.FETCH_CATEGORIES,
        payload: categories,
    });
};
// #endregion

// #region product list page filters
export const fetchFilters = () => async (dispatch, getState) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    let filters = [];
    try {
        dispatch({
            type: types.FETCH_FILTERS,
            payload: [],
        });
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get('/api/products/filters', object);
        filters = response.data;
    } catch (error) {
        console.error('/api/Filters Get Error: ', error);
    }

    if (filters) {
        dispatch({
            type: types.FETCH_FILTERS,
            payload: filters,
        });
    }
};

// #endregion

export const fetchDefinitions = () => async (dispatch) => {
    let definitions = null;
    dispatch({
        type: types.FETCH_DEFINITIONS,
        payload: {},
    });
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get('/api/definitions', object);
        definitions = response.data;
    } catch (error) {
        console.error('/api/definitions GET Error: ', error);
        throw error;
    }

    if (definitions) {
        dispatch({
            type: types.FETCH_DEFINITIONS,
            payload: definitions,
        });
    }
};

export const fetchPrepackRun = (classCodes) => async (dispatch, getState) => {
    let prepackrun = [];
    let params = {};
    if (classCodes != null) {
        let needPrepacks;
        if (!Array.isArray(classCodes)) {
            needPrepacks = classCodes.split(',');
        } else {
            needPrepacks = classCodes;
        }
        const existingPrepacks = getState().data.prepackrun;

        const notFoundPrepacks = _.differenceWith(needPrepacks, existingPrepacks, (a, b) => {
            return a === b.class_cd;
        });

        if (_.isEmpty(notFoundPrepacks)) {
            return existingPrepacks;
        }
        params = {
            class_cd: notFoundPrepacks.join(','),
        };
    }
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/prepackrun', object);
        prepackrun = response.data;
    } catch (error) {
        console.error('/api/Product Prepack Run Get Error: ', error);
    }

    dispatch({
        type: types.FETCH_PREPACK_RUN,
        payload: prepackrun,
    });
};

// #endregion

// #region Shipping method
export const fetchShippingMethods = () => async (dispatch) => {
    let shippingMethod = null;
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get('/api/shipping_methods', object);
        shippingMethod = response.data.shipping_methods ?? [];
    } catch (error) {
        console.error('/api/Shipping method Get Error: ', error);
    }

    dispatch({
        type: types.FETCH_SHIPPING_METHODS,
        payload: shippingMethod,
    });
};
// #endregion

export const scanItemToCart =
    (upc, quantity = 1, completionHandler, completionError) =>
    async (dispatch) => {
        if (!upc) return;
        const object = configuration.fetchWebsiteObject();
        try {
            const response = await axios.post(
                UrlEnum.API_SCAN_ITEM_TO_CART,
                {
                    upc,
                    quantity,
                },
                object,
            );

            const { shopping_cart, error } = response.data;
            if (shopping_cart != null) {
                dispatch({
                    type: types.FETCH_CART_ITEMS,
                    cart: shopping_cart.cart_items,
                });
                dispatch({
                    type: types.FETCH_CART_ITEMS_COUNT,
                    payload: shopping_cart.count,
                });
            } else {
                throw error;
            }

            if (completionHandler) {
                completionHandler(response.data);
            }
        } catch (error) {
            if (completionError) {
                completionError(error);
            }
        }
    };

export const addShoppingCartItems =
    (cartItems, completionHandler, completionError) => async (dispatch) => {
        const object = configuration.fetchWebsiteObject();
        if (Array.isArray(cartItems)) {
            cartItems.forEach((itemObject) => {
                if (!_.isEmpty(itemObject) && 'item' in itemObject) {
                    itemObject.item.images = null;
                }
            });
        }
        const itemLimit = 30;
        try {
            let response;
            if (Array.isArray(cartItems)) {
                const chunks = _.chunk(cartItems, itemLimit);

                for (let i = 0, chunk; i < chunks.length; i++) {
                    chunk = chunks[i];
                    response = await axios.post(`/api/shopping_cart`, chunk, object);
                }
            } else {
                response = await axios.post(`/api/shopping_cart`, [cartItems], object);
            }

            const cartResponse = await axios.get('/api/shopping_cart', object);
            dispatch({
                type: types.FETCH_CART_ITEMS,
                cart: cartResponse.data.cart_items,
            });
            dispatch({
                type: types.FETCH_CART_ITEMS_COUNT,
                payload: cartResponse.data.count,
            });

            if (completionHandler) {
                completionHandler(response.data);
            }
        } catch (error) {
            console.error(`/api/shopping_cart`, error);
            if (error.response.status === 401) {
                window.location.reload();
                return;
            }
            if (completionError) {
                completionError({ errorString: 'The cart item does not exist.' });
            }
        }
    };

// #region Cart info
export const updateShoppingCartItems =
    (cartItems, completionHandler, completionError) => async (dispatch) => {
        const object = configuration.fetchWebsiteObject();
        try {
            const response = await axios.patch(`/api/shopping_cart`, cartItems, object);
            dispatch({
                type: types.FETCH_CART_ITEMS,
                cart: response.data.cart_items,
            });
            dispatch({
                type: types.FETCH_CART_ITEMS_COUNT,
                payload: response.data.count,
            });
            if (completionHandler) {
                completionHandler(response);
            }
        } catch (error) {
            console.error(`/api/shopping_cart`, error);
            if (completionError) {
                completionError({ errorString: 'The cart item does not exist.' });
            }
        }
    };

export const removeCartItems =
    (cartItemsIDs, completionHandler, completionError) => async (dispatch) => {
        const object = configuration.fetchWebsiteObject();
        try {
            const response = await axios.delete(`/api/shopping_cart/items`, {
                data: { ids: cartItemsIDs },
                ...object,
            });
            const cartResponse = await axios.get('/api/shopping_cart', object);
            dispatch({
                type: types.FETCH_CART_ITEMS,
                cart: cartResponse.data.cart_items,
            });
            dispatch({
                type: types.FETCH_CART_ITEMS_COUNT,
                payload: cartResponse.data.count,
            });
            if (completionHandler) {
                completionHandler(response);
            }
        } catch (error) {
            console.error(error);
            if (completionError) {
                completionError({ errorString: 'The cart item does not exist.' });
            }
        }
    };

export const removeAllCartItems = (completionHandler, completionError) => async (dispatch) => {
    const object = configuration.fetchWebsiteObject();
    const response = await axios.delete('/api/shopping_cart', object);
    if (completionHandler) {
        completionHandler();
    }
    dispatch({
        type: types.FETCH_CART_ITEMS,
        cart: response.data.cart_items,
    });
    dispatch({
        type: types.FETCH_CART_ITEMS_COUNT,
        payload: 0,
    });
};

export const fetchCartItemsCount = () => async (dispatch) => {
    dispatch({
        type: types.FETCH_CART_ITEMS_COUNT,
        payload: 0,
    });
    const object = configuration.fetchWebsiteObject();
    const response = await axios.get('/api/shopping_cart_count', object);
    if (response.data.count == null) {
        dispatch({
            type: types.FETCH_CART_ITEMS_COUNT,
            payload: null,
        });
    } else {
        dispatch({
            type: types.FETCH_CART_ITEMS_COUNT,
            payload: response.data.count,
        });
    }
};
export const reorderOrder = (order_number) => async (dispatch) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const cartResponse = await axios.post(`/api/orders/${order_number}/reorder`, {}, object);

        dispatch({
            type: types.FETCH_CART_ITEMS,
            cart: cartResponse.data.cart_items,
        });
        dispatch({
            type: types.FETCH_CART_ITEMS_COUNT,
            payload: cartResponse.data.count,
        });
    } catch (error) {
        console.error('Error reorder order:', error);
        throw error;
    }
};

export const orderReservedCart = (reserved_id) => async (dispatch) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post(`/api/reserved_cart/${reserved_id}`, {}, object);
        dispatch({
            type: types.FETCH_CART_ITEMS,
            cart: response.data.cart_items,
        });
        dispatch({
            type: types.FETCH_CART_ITEMS_COUNT,
            payload: response.data.count,
        });
    } catch (error) {
        console.error('Error ordering reserved cart:', error);
        throw error;
    }
};

export const reorderInvoice = (invoice_number) => async (dispatch) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const cartResponse = await axios.post(
            `/api/invoices/${invoice_number}/reorder`,
            {},
            object,
        );
        dispatch({
            type: types.FETCH_CART_ITEMS,
            cart: cartResponse.data.cart_items,
        });
        dispatch({
            type: types.FETCH_CART_ITEMS_COUNT,
            payload: cartResponse.data.count,
        });
    } catch (error) {
        console.error('Error reorder order:', error);
        throw error;
    }
};

export const fetchCart = (params) => async (dispatch) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    const object = configuration.fetchWebsiteObject(params);
    const response = await axios.get('/api/shopping_cart', object);
    dispatch({
        type: types.FETCH_CART_ITEMS,
        cart: response.data.cart_items,
    });
    dispatch({
        type: types.FETCH_CART_ITEMS_COUNT,
        payload: response.data.count,
    });
    return response?.data;
};
// #endregion

export const setCart = (cart) => (dispatch) => {
    if (!_.isEmpty(cart)) {
        dispatch({
            type: types.FETCH_CART_ITEMS,
            cart,
        });
    }
};

export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product,
});

// #region Compare Products
export const addToCompare = (product) => (dispatch) => {
    dispatch(addToCompareUnsafe(product));
};

export const removeFromCompare = (product_id) => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id,
});
// #endregion

// #region Snackbar
export const setSnackbar =
    (snackbarObject = {}) =>
    (dispatch) => {
        dispatch({
            type: types.FETCH_SNACKBAR_STATUS,
            payload: snackbarObject,
        });
    };

export const setBackdrop =
    (backdropObject = {}) =>
    (dispatch) => {
        dispatch({
            type: types.FETCH_BACKDROP_STATUS,
            payload: backdropObject,
        });
    };

// #region WS
export const addWebsocketMsg =
    (msgObj = {}) =>
    (dispatch) => {
        dispatch({
            type: types.ADD_WS_MSG,
            payload: msgObj,
        });
    };

export const clearWebsocketMsg = (requestName) => (dispatch) => {
    dispatch({
        type: types.CLEAR_WS_MSG,
        payload: { requestName },
    });
};

// Used for websocket requests
export const RequestNames = {
    CREATE_ORDER: 'create_order',
};
