import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import {
    UrlEnum,
    isCustomerSelected,
    isPointOfSaleWebsite,
    isSalesRepWebsite,
    salesRepCustomerSelected,
} from 'util/utils';
import { useHistory } from 'react-router-dom';
import ClearSelectedCustomerModal from 'components/Modal/ClearSelectedCustomerModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuickSelectCustomer from 'components/Modal/QuickSelectCustomer';
import ComponentNames from 'util/ComponentNames';

// onlyAllowClearing is for POS to disable the options
function SelectCustomerID({ onlyAllowClearing = false }) {
    const serviceType = useSelector((state) => state.serviceType);
    const auth = useSelector((state) => state.auth);
    const selectedCustomer = useSelector((state) => state.selectedCustomer);

    const [anchorElSelectedCustomer, setAnchorElSelectedCustomer] = useState(null);
    const [clearCustomerModalOpened, setClearCustomerModalOpened] = useState(false);
    const [quickSelectCustomerOpen, setQuickSelectCustomerOpen] = useState(false);

    const history = useHistory();

    if ((!isPointOfSaleWebsite(serviceType) && !isSalesRepWebsite(serviceType)) || !auth) {
        return null;
    }

    let customerSelected = false;
    if (isSalesRepWebsite(serviceType)) {
        customerSelected = salesRepCustomerSelected(serviceType, auth);
    } else {
        customerSelected = isCustomerSelected(auth);
    }

    let customerText = 'Select a customer...',
        secondaryText;
    if (customerSelected) {
        if (isEmpty(selectedCustomer.customer_name)) {
            customerText = `Customer ID: ${selectedCustomer.customer_id}`;
        } else {
            customerText = `Customer: ${selectedCustomer.customer_name}`;
            secondaryText = `ID: ${selectedCustomer.customer_id}`;
        }
    }

    let storeNumText = '';
    if (auth?.oms_customer?.store_num) {
        storeNumText = `Chain Store #: ${auth.oms_customer.store_num}`;
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box
                    sx={{ p: { xs: 0, md: '6px 8px' }, cursor: 'pointer' }}
                    data-testid={'select-customer-component'}
                    onClick={(e) => {
                        if (customerSelected) {
                            setAnchorElSelectedCustomer(e.currentTarget);
                        } else if (!onlyAllowClearing) {
                            history.push(UrlEnum.CUSTOMER_LIST);
                        }
                    }}
                >
                    <Box component="span">{customerText}</Box>
                    {secondaryText && (
                        <Box sx={{ color: 'gray', fontSize: '0.8rem' }}>{secondaryText}</Box>
                    )}
                    {storeNumText && (
                        <Box sx={{ color: 'gray', fontSize: '0.8rem' }}>{storeNumText}</Box>
                    )}
                </Box>
                <Box>
                    <IconButton
                        onClick={() => setQuickSelectCustomerOpen(true)}
                        id={ComponentNames.QuickSelectButton}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
            </Box>
            <Menu
                anchorEl={anchorElSelectedCustomer}
                open={Boolean(anchorElSelectedCustomer)}
                onClose={() => setAnchorElSelectedCustomer(null)}
            >
                {!onlyAllowClearing && (
                    <MenuItem
                        onClick={() => {
                            history.push(
                                `${UrlEnum.CUSTOMER_DETAIL_NID}/${encodeURIComponent(
                                    selectedCustomer.customer_id,
                                )}`,
                            );
                            setAnchorElSelectedCustomer(null);
                        }}
                    >
                        {'View Customer Details...'}
                    </MenuItem>
                )}
                {!onlyAllowClearing && (
                    <MenuItem
                        onClick={() => {
                            history.push(UrlEnum.CUSTOMER_LIST);
                            setAnchorElSelectedCustomer(null);
                        }}
                        divider
                    >
                        {'Select New Customer...'}
                    </MenuItem>
                )}
                <MenuItem
                    sx={{ color: 'red' }}
                    onClick={() => {
                        setClearCustomerModalOpened(true);
                        setAnchorElSelectedCustomer(null);
                    }}
                >
                    {'Clear Selected Customer...'}
                </MenuItem>
            </Menu>
            <ClearSelectedCustomerModal
                open={clearCustomerModalOpened}
                onClose={() => setClearCustomerModalOpened(false)}
            />
            <QuickSelectCustomer
                open={quickSelectCustomerOpen}
                onClose={() => setQuickSelectCustomerOpen(false)}
            />
        </>
    );
}

export default SelectCustomerID;
