import axios from 'axios';
import configuration from './configuration';
import _ from 'lodash';

export const saveWebCustomer = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/web_customers', params, object);
        return response.data;
    } catch (error) {
        console.error(error);
        return;
    }
};

export const fetchResetPasswordUserInformation = async (email, reset_password_token) => {
    try {
        const object = configuration.fetchWebsiteObject({ email, reset_password_token });
        const res = await axios.get('/api/reset_password_web_customer', object);
        if (_.isEmpty(res.data)) {
            return null;
        }
        return res.data;
    } catch (error) {
        console.error('Error User:', error);
        return;
    }
};

export const resetPasswordForWebcustomer = async (params) => {
    let webcustomers = null;
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/reset_password_web_customer', params, object);
        webcustomers = response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }

    return webcustomers;
};

export const changePassword = async (params) => {
    let webcustomers = null;
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/change_password', params, object);
        webcustomers = response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }

    return webcustomers;
};

export const sendForgotPasswordLink = async (email) => {
    let success = false;

    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get(`/api/forgot_password/${email}`, object);
        success = response.data;
    } catch (error) {
        console.error(error);
    }

    return success;
};
