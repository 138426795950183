import { isEmpty } from 'lodash';
import axios from 'axios';
import configuration from './configuration';

const fetchProductList = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/products', object);
        return response.data;
    } catch (error) {
        console.error('Fetching product list GET error', error);
        throw error;
    }
};

const fetchProductCount = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/products/count', object);
        return response.data;
    } catch (error) {
        console.error('Fetching product count GET error', error);
        throw error;
    }
};

const fetchProductDetail = async (params = {}) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/products', object);
        const { data } = response;

        if (isEmpty(data)) {
            return {};
        }

        const { products } = data;

        if (isEmpty(products)) {
            return {};
        }

        return products[0];
    } catch (error) {
        console.error('Product Detail GET Error: ', error);
        throw error;
    }
};

const fetchProductClassDefinitions = async (class_cd) => {
    try {
        const object = configuration.fetchWebsiteObject({
            class_cd,
        });
        const response = await axios.get('/api/classdefinition', object);
        return response.data;
    } catch (error) {
        console.error('Fetch product more notes GET Error: ', error);
        throw error;
    }
};

const fetchRelatedProductList = async (params) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    if (params.limit == null) {
        params.limit = 20;
    }
    if (params.offset == null) {
        params.offset = 0;
    }

    params.pavodb = true;

    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/products', object);
        if (isEmpty(response.data)) {
            return null;
        }

        return response.data.products;
    } catch (error) {
        console.error('Related product List Get Error: ', error);
        return null;
    }
};

const fetchProductPO = async (itemNumbers) => {
    const object = configuration.fetchWebsiteObject({ itemNumbers });
    const result = await axios.get('/api/product_po', object);
    return result.data;
};

const fetchProductsPrice = async (body = []) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/products/price', body, object);
        return response.data;
    } catch (error) {
        console.error('Fetch product more notes GET Error: ', error);
        throw error;
    }
};

export {
    fetchProductList,
    fetchProductCount,
    fetchProductDetail,
    fetchProductClassDefinitions,
    fetchRelatedProductList,
    fetchProductPO,
    fetchProductsPrice,
};
